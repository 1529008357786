import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createTRPCReact } from "@trpc/react-query";
import superjson from "superjson";
import { AppRouter } from "dependaclock-server";
import { Route, Routes } from "react-router-dom";
import LoginSwitcher from "./aidant/LoginSwitcher.tsx";
import MainClockAndAptPanel from "./clock/MainClockAndAptPanel.tsx";
import AidantMainPanel from "./aidant/AidantMainPanel.tsx";
import { observable } from "@trpc/server/observable";
import { httpBatchLink, TRPCLink } from "@trpc/client";
const queryClient = new QueryClient();

export const trpc = createTRPCReact<AppRouter>();
const mode = import.meta.env.MODE;
const clientBuildNumber = import.meta.env.VITE_BUILD_NUMBER;
if (!clientBuildNumber) {
  throw new Error("no build number");
}

const prefix = mode === "development" ? "http://" : "https://";

const hostDirty = import.meta.env.VITE_DEV_HOST || window.location.host;
const host = hostDirty.replace("http://", "");
const url = `${prefix}${host}/dependaclockApi`;

function getBuildNumberFromResponse(response: Response) {
  const headers = response.headers;
  const build = headers.get("x-build-number");
  console.log("build", build);
  return build;
}

const customLink: TRPCLink<AppRouter> = () => {
  return ({ next, op }) => {
    // this is when passing the result to the next link
    // each link needs to return an observable which propagates results
    return observable((observer) => {
      const unsubscribe = next(op).subscribe({
        next(value) {
          const response = value.context?.response as Response | undefined;
          const serverBuildNumber = response
            ? getBuildNumberFromResponse(response)
            : null;
          if (serverBuildNumber && serverBuildNumber !== clientBuildNumber) {
            console.log("force reload");
            console.log("serverBuildNumber", serverBuildNumber);
            console.log("clientBuildNumber", clientBuildNumber);
            location.reload();
          }
          response && getBuildNumberFromResponse(response);
          observer.next(value);
        },
        error(err) {
          observer.error(err);
        },
        complete() {
          observer.complete();
        },
      });
      return unsubscribe;
    });
  };
};

const trpcClient = trpc.createClient({
  links: [
    customLink,
    httpBatchLink({
      url,
      fetch(url, options) {
        return fetch(url, {
          ...options,
          credentials: "include",
        });
      },
      transformer: superjson,
    }),
  ],
});

const GuardedAidantRoute = () => (
  <LoginSwitcher>
    <AidantMainPanel />
  </LoginSwitcher>
);

const GuardedMainClockRoute = () => (
  <LoginSwitcher>
    <MainClockAndAptPanel />
  </LoginSwitcher>
);

const Root = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <trpc.Provider client={trpcClient} queryClient={queryClient}>
        <Routes>
          <Route path="/" element={<GuardedMainClockRoute />}></Route>
          <Route path="/aidant" element={<GuardedAidantRoute />}></Route>
        </Routes>
      </trpc.Provider>
    </QueryClientProvider>
  );
};

export default Root;
